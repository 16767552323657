import React, { useState, useEffect, useRef, useCallback } from "react";
import { ajaxCaller } from "../ajaxCaller";
import { getAccessToken, isKontakAdmin } from "../authentification";
import Select2 from "react-select2-wrapper";
import CustomSlider from "./components/customSlider";
import moment from "moment";
import { PrivilegeChecker, Privileges } from "../privilegeChecker";
import { DIGITAIL_PIMS_TYPE_ID, SHEPHERD_PIMS_TYPE_ID, SHEPHERD_CLIENT_BASE_URL, CREDENTIAL_SUPPORTED_PIMS_IDS } from "./helpers/constants";
import TextInput from "./components/TextInput";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useMutation } from "@tanstack/react-query";
import { sortPimsTypeByLowercaseName, isValidBaseUrl, appendPimsPartnerIDToUrl } from "./helpers/utils";
import { flags } from '../rollout';

// Helpers
import { PIMS_DETAILS } from "./helpers/constants";

import { 
  createLabelByPimsTypeID, 
  deployFieldPIMSPartnerID,
  createPlaceholderToUrlBaseField,
  validateURLById,
  getClinicIDFromDigitail,
} from "./helpers/utils";

const digitailId = PIMS_DETAILS.digitail.id;

// Component
const EditLocation = (props) => {
  const [isLocationMatchError, setIsLocationMatchError] = useState(false);
  const [clinicsList, setClinicsList] = useState([]);
  const [regions, setRegions] = useState(null);
  const getRegions = () => {
    ajaxCaller("Region/GetRegions", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    }).then((regions) => {
      if (regions.isError) {
      } else {
        setRegions(regions);
      }
    });
  };
  useEffect(() => {
    if (regions == null) {
      getRegions();
    }
  }, [regions]);
  const [groupId, setGroupId] = useState('');
  const [facilityName, setFacilityName] = useState('');
  const [telephonyTypeID, setTelephonyTypeID] = useState('');
  const [containerID, setContainerID] = useState('');
  const [tenantID, setTenantID] = useState('');
  const [pimtypes, setPimtypes] = useState(null);
  const getPimtypes = () => {
    ajaxCaller("PIMSType/PIMSTypes", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    }).then((pimtypes) => {
      if (pimtypes.isError) {
      } else {
        setPimtypes(pimtypes);
      }
    });
  };
  useEffect(() => {
    if (pimtypes == null) {
      getPimtypes();
    }
  }, [pimtypes]);
  const [groupKey, setGroupKey] = useState('');
  const [pimsapiKey, setPIMSAPIKey] = useState('');
  const [pimsOrgID, setPIMSOrgID] = useState('');
  const [pimsPartnerID, setPIMSPartnerID] = useState('');
  const [outboundSmsNumber, setOutboundSmsNumber] = useState('');
  const [pimsClientBaseUrl, setPimsClientBaseUrl] = useState('');
  const [cloudRetrieverApiKey, setCloudRetrieverApiKey] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [addressLine, setAddressLine] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [regionId, setRegionId] = useState(1);
  const [stateId, setStateId] = useState('');
  const [timeZoneId, setTimeZoneId] = useState('');
  const [businessHours, setBusinessHours] = useState(null);
  const [averageClientValue, setAverageClientValue] = useState('');
  const [appointmentsPercentage, setAppointmentsPercentage] = useState('');
  const [pimsTypeID, setPIMSTypeID] = useState(1);
  const [mondayIsClosed, setMondayIsClosed] = useState(false);
  const [tuesdayIsClosed, setTuesdayIsClosed] = useState(false);
  const [wednesdayIsClosed, setWednesdayIsClosed] = useState(false);
  const [thursdayIsClosed, setThursdayIsClosed] = useState(false);
  const [fridayIsClosed, setFridayIsClosed] = useState(false);
  const [saturdayIsClosed, setSaturdayIsClosed] = useState(false);
  const [sundayIsClosed, setSundayIsClosed] = useState(false);
  const [hideCallRecordings, sethideCallRecordings] = useState(false);
  const [features, setFeature] = useState({
    useSmsFeature: false,
    useCalendarFeature: false
  });
  const [tokenId, setTokenId] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [serviceName, setServiceName] = useState('digitail');
  const [tokenType, setTokenType] = useState('Bearer');
  const [expiresIn, setExpiresIn] = useState(-1);
  const [refreshToken, setRefreshToken] = useState('');
  const [sameAsOutboundCID, setSameAsOutboundCID] = useState(false);
  const [outboundCallerID, setOutboundCallerID] = useState('');
  const [validationStatus, setValidationStatus] = useState(null);
  const getGroup = () => {
    ajaxCaller("Group/GetGroup/" + props.match.params.groupId, {
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      method: "get",
    }).then((response) => {
      if (!response.isError) {
        console.log('Group response');
        console.log(response);
        setGroupId(response.id);
        setFacilityName(response.name);
        setTelephonyTypeID(response.telephonyTypeID);
        setContainerID(response.containerID);
        setTenantID(response.tenantID);
        setPIMSTypeID(response.pimsTypeID == null ? -1 : response.pimsTypeID);
        setPIMSAPIKey(response.pimsapiKey);
        setPIMSOrgID(response.pimsOrgID);
        setPIMSPartnerID(response.pimsPartnerID);
        setOutboundSmsNumber(response.outboundSmsNumber);
        setPimsClientBaseUrl(response.pimsClientBaseUrl);
        setCloudRetrieverApiKey(response.cloudRetrieverApiKey);
        setStreetAddress(response.streetAddress);
        setCity(response.city);
        setZipCode(response.zipCode);
        setRegionId(response.regionId);
        setStateId(response.stateId);
        setGroupKey(response.groupKey);
        setTimeZoneId(response.timeZoneId);
        setAddressLine(response.addressLine);
        setAppointmentsPercentage(response.appointmentsPercentage);
        setAverageClientValue(response.averageClientValue);
        setMondayIsClosed(response.businessHours.mondayIsClosed);
        setTuesdayIsClosed(response.businessHours.tuesdayIsClosed);
        setWednesdayIsClosed(response.businessHours.wednesdayIsClosed);
        setThursdayIsClosed(response.businessHours.thursdayIsClosed);
        setFridayIsClosed(response.businessHours.fridayIsClosed);
        setSaturdayIsClosed(response.businessHours.saturdayIsClosed);
        setSundayIsClosed(response.businessHours.sundayIsClosed);
        sethideCallRecordings(response.hideCallRecordings);
        setFeature({
          useSmsFeature: response.useSmsFeature,
          useCalendarFeature: response.useCalendarFeature
        });
        setSameAsOutboundCID(response.sameAsOutboundCID)
        setOutboundCallerID(response.outboundCallerID);
      }
    });
  };

  const GetBusinessHours = () => {
    ajaxCaller("Group/GetBusinessHours/" + props.match.params.groupId, {
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      method: "get",
    }).then((response) => {
      if (!response.isError) {
        setBusinessHours(response);
      }
    });
  };

  const getGroupCallback = () => {
    getGroup();
    getToken();
    GetBusinessHours();
  };
  useEffect(getGroupCallback, []);

  const [states, setStates] = useState(null);
  const getStates = () => {
    ajaxCaller("States/GetStates", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    }).then((states) => {
      setStates(states);
    });
  };
  useEffect(() => {
    if (states == null) {
      getStates();
    }
  }, [states]);

  const [timeZones, setTimeZones] = useState(null);
  const getTimeZones = () => {
    ajaxCaller("TimeZone/GetAll", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    }).then((response) => {
      setTimeZones(response);
    });
  };
  useEffect(() => {
    if (timeZones == null) {
      getTimeZones();
    }
  }, [timeZones]);

  function extractTime(time) {
    return moment(time).format("hh:mm A");
  }

  function toTime(time) {
    return moment(time, "HH:mm:ss").valueOf();
  }

  const fetchClinicsList = async () => {
    const response = await ajaxCaller("shepherd/clinics", {
      method: 'post',
      body: JSON.stringify({ page: 1, rpp: 1000 }),
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    });
    setClinicsList(response?.item);
    return response?.item;
  };

  const compareFacilityNameToShepherdClinics = (facilityName, clinics) => {
    const clinicsWithMatchingName = clinics.filter(clinic => clinic.name.toLowerCase().includes(facilityName.toLowerCase().trim()));
    
    // set the pimsOrgId if there's only one clinic with a matching name
    if (clinicsWithMatchingName.length === 1) {
      setFacilityName(clinicsWithMatchingName[0]?.name) // autocomplete the facility name
      setPIMSOrgID(clinicsWithMatchingName[0]?.id);
    }

    // If we have more than one clinic with a matching name, we have to show an error with all the options
    return clinicsWithMatchingName.length === 1;
  }

  const checkLocationMutation = useMutation({
    mutationFn: () => fetchClinicsList()
      .then(shepherdClinics => compareFacilityNameToShepherdClinics(facilityName, shepherdClinics)),
    onSuccess: (isLocationNameCorrect) => {
      setIsLocationMatchError(!isLocationNameCorrect);
    },
    onError: () => {
      setIsLocationMatchError(false);
    }
  });
  
  const handlePimsTypeChange = (event) => {
    const selectedPimstypeId = Number(event.target.value);
    setPIMSTypeID(selectedPimstypeId);
  
    if (selectedPimstypeId === SHEPHERD_PIMS_TYPE_ID) { 
      setPimsClientBaseUrl(SHEPHERD_CLIENT_BASE_URL);
      checkLocationMutation.mutate();
    }
  };

  const handleLocationNameBlur = () => {
    if (pimsTypeID === SHEPHERD_PIMS_TYPE_ID) {
      checkLocationMutation.mutate();
    }
  }

  const getToken = () => {
    ajaxCaller("OAuth2Token/GetOAuth2TokenAsync/" + props.match.params.groupId + "/" + serviceName, {
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      method: "get",
    }).then((response) => {
      if (!response.isError) {
        setTokenId(response.id);
        setAccessToken(response.accessToken);
        setServiceName(response.serviceName);
        setTokenType(response.tokenType);
        setExpiresIn(response.expiresIn);
        setRefreshToken(response.refreshToken);
      }
    }).catch(err => {
      setAccessToken("");
    });
  };

  const updateToken = () => {
    ajaxCaller("OAuth2Token/UpdateOAuth2Token/", {
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      method: "put",
      body: JSON.stringify({
        id: tokenId,
        accessToken: accessToken,
      }),
    }).then((response) => {
      console.log({response});
      if (!response.isError) {
      }
    });
  };

  const createToken = () => {
    ajaxCaller("OAuth2Token/CreateOAuth2Token/", {
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      method: "post",
      body: JSON.stringify({
        groupId: groupId,
        serviceName: serviceName,
        tokenType: tokenType,
        expiresIn: expiresIn,
        accessToken: accessToken,
        refreshToken: refreshToken,
      }),
    }).then((response) => {
      console.log({response});
      if (!response.isError) {
        // window.location = "/settings/locations";
      }
    });
  };

  const updateGroup = () => {
    businessHours.mondayIsClosed = mondayIsClosed;
    businessHours.tuesdayIsClosed = tuesdayIsClosed;
    businessHours.wednesdayIsClosed = wednesdayIsClosed;
    businessHours.thursdayIsClosed = thursdayIsClosed;
    businessHours.fridayIsClosed = fridayIsClosed;
    businessHours.saturdayIsClosed = saturdayIsClosed;
    businessHours.sundayIsClosed = sundayIsClosed;
    ajaxCaller("Group/UpdateGroup/", {
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      method: "put",
      body: JSON.stringify({
        id: groupId,
        name: facilityName,
        telephonyTypeID: telephonyTypeID,
        containerID: containerID,
        tenantID: tenantID,
        pimsTypeID: pimsTypeID,
        pimsapiKey: pimsapiKey,
        pimsOrgID: pimsOrgID,
        pimsPartnerID: pimsPartnerID,
        outboundSmsNumber: sameAsOutboundCID ? outboundCallerID : outboundSmsNumber,
        pimsClientBaseUrl: pimsClientBaseUrl,
        cloudRetrieverApiKey: cloudRetrieverApiKey,
        useCalendarFeature: features.useCalendarFeature,
        useSmsFeature: features.useSmsFeature,
        regionId: regionId,
        stateId: stateId,
        streetAddress: streetAddress,
        addressLine: addressLine,
        city: city,
        zipCode: zipCode,
        businessHours: businessHours,
        timeZoneId: timeZoneId,
        averageClientValue: averageClientValue,
        appointmentsPercentage: appointmentsPercentage,
        hideCallRecordings: hideCallRecordings,
        sameAsOutboundCID: sameAsOutboundCID,
        outboundCallerID: outboundCallerID

      }),
    }).then((response) => {
      if (!response.isError) {
        window.location = "/settings/locations";
      }
    });
  };
  
  const clickHandler = event => {
    if (pimsTypeID === DIGITAIL_PIMS_TYPE_ID) {
      if (accessToken !== null && accessToken !== "") {
        if (tokenId === null || tokenId ==='') {
          createToken();
        }
        else {
          updateToken();
        }
      }
    }
    updateGroup();
  };

  const validateCredentials = async () => {
    try {
      const response = await ajaxCaller("Group/ValidateCredentials/", {
        headers: new Headers({
          Authorization: "Bearer " + getAccessToken(),
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
        method: "POST",
        body: JSON.stringify({
          GroupId: groupId,
          PimsTypeID: pimsTypeID,
          PimsApiKey: pimsapiKey,
          PimsOrgID: pimsOrgID,
          AccessToken: accessToken,
        }),
      });
  
      if (response?.isValid === true) {
        setValidationStatus("valid");
      } else {
        setValidationStatus("invalid");
      }
    } catch (err) {
      console.error("Validation failed:", err);
      setValidationStatus("invalid");
    }
  };
  
  const validateDuplicateCredentials = async () => {
    if(!flags.preventSharedAuthCredentials.isEnabled()){
      return;
    }

    try {
      const response = await ajaxCaller("Group/ValidateDuplicateCredentials/", {
        headers: new Headers({
          Authorization: "Bearer " + getAccessToken(),
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
        method: "POST",
        body: JSON.stringify({
          GroupId: groupId,
          PimsTypeID: pimsTypeID,
          PimsApiKey: pimsapiKey,
          PimsOrgID: pimsOrgID,
          AccessToken: accessToken,
        }),
      });
  
      if (response?.isDuplicate === true) {
        const message = response?.errorMessage;
        if(isDigitailSelected){
          setErrorOAuth2Token(message);
        }
        else{
          setErrorPimsOrgId(message);
        }

      } else {
        setErrorPimsOrgId("");
        setErrorOAuth2Token("");
      }

    } catch (err) {
        console.error("validateDuplicateCredentials error", err);
    }
  };

  const handlePimsOrgIDBlur = () => {
    if(isDigitailSelected){
      return;
    }

    validateDuplicateCredentials();
  }

  const shouldDisableButton = () => {
    if(!flags.preventSharedAuthCredentials.isEnabled()){
      return false;
    }

    return hasValidationErrors;
  }

  const isShepherdSelected = pimsTypeID === SHEPHERD_PIMS_TYPE_ID;
  const isDigitailSelected = pimsTypeID === DIGITAIL_PIMS_TYPE_ID;
  const shouldRenderPimsApiKey = () => isKontakAdmin() && !isDigitailSelected && !isShepherdSelected;
  const shouldRenderPimsPartnerId = () => isKontakAdmin() &&  deployFieldPIMSPartnerID(pimsTypeID.toString());
  
  // Block Start: Function to dynamic fields ----------------------------------------------------------------------------------------------------------------------------------------------------  

  // Refs
  const urlBaseInputRef = useRef(null)
  const [errorURLBaseInput, setErrorURLBaseInput] = useState("")
  const [errorPimsOrgId, setErrorPimsOrgId] = useState("")
  const [errorOAuth2Token, setErrorOAuth2Token] = useState("")

  const hasValidationErrors = !!(errorPimsOrgId || errorOAuth2Token);

  /**
   * Handles the blur event to validate the URL and update the input field's style.
   *
   * Validates the URL using `validateURLById` when the input field loses focus. Updates the input's CSS class:
   * - Removes 'error' class if valid.
   * - Adds 'error' class if invalid.
   */

  const handleBlur = useCallback(() => {
    if (urlBaseInputRef.current) {
      if (pimsClientBaseUrl) {
        const isValid = validateURLById(pimsTypeID.toString(), pimsClientBaseUrl);
  
        if (isValid) {
          urlBaseInputRef.current.classList.remove('error');
          setErrorURLBaseInput("");
        } else {
          const provetId = PIMS_DETAILS.provet.id;
          const errorMessage = pimsTypeID.toString() === provetId
            ? "The URL does not meet the desired structure. Update the Provet Cloud ID to generate a new URL."
            : "The URL does not meet the desired structure.";
  
          urlBaseInputRef.current.classList.add('error');
          setErrorURLBaseInput(errorMessage);
        }
      } else {
        urlBaseInputRef.current.classList.remove('error');
        setErrorURLBaseInput("");
      }
    }
  }, [pimsClientBaseUrl, pimsTypeID]);
  


  /**
   * Clears the base URL input field when the `pimsTypeID` changes.
   * This ensures the URL is reset and will be validated again when entered.
   */

  useEffect(() => {
    if( 
      pimsTypeID.toString() !== PIMS_DETAILS.provet.id &&
      pimsTypeID.toString() !== PIMS_DETAILS.digitail.id
    ){
      setPimsClientBaseUrl("")
    }

    if( 
      pimsTypeID.toString() === PIMS_DETAILS.digitail.id
    ){
      setPimsClientBaseUrl(PIMS_DETAILS.digitail.urlBase.structureUrl)
    }


    if( 
      pimsTypeID.toString() !== PIMS_DETAILS.digitail.id
    ){
      setAccessToken("")
      setPIMSOrgID("")
    }
    setErrorURLBaseInput("");
    setErrorPimsOrgId("");

  }, [pimsTypeID])


  /**
   * useEffect hook to update the `pimsClientBaseUrl` state based on the `pimsTypeID` and `pimsPartnerID` values.
   * 
   * This effect checks if the `pimsTypeID` matches the Provet ID defined in `PIMS_DETAILS`.
   * If `pimsPartnerID` is provided, it constructs a new URL using `pimsPartnerID` and sets it to `pimsClientBaseUrl`.
   * If `pimsPartnerID` is not provided, it clears the `pimsClientBaseUrl`.
   * 
   * Dependencies: `pimsPartnerID`, `pimsTypeID`
   */

  useEffect(() => {
    const provetId = PIMS_DETAILS.provet.id;
  
    if (pimsTypeID.toString() === provetId && pimsPartnerID) {
      const baseUrl = isValidBaseUrl(pimsClientBaseUrl) ? pimsClientBaseUrl : "https://us.provetcloud.com/";
      const updatedUrl = appendPimsPartnerIDToUrl(baseUrl, pimsPartnerID);
      setPimsClientBaseUrl(updatedUrl);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pimsPartnerID, pimsTypeID]);

  /**
  * useEffect hook to trigger the `handleBlur` function based on the `pimsClientBaseUrl` value.
  * 
  * This effect checks if the `pimsTypeID` matches the Provet ID defined in `PIMS_DETAILS` and if `pimsPartnerID` is provided.
  * If both conditions are met, it calls the `handleBlur` function.
  * 
  * Dependencies: `pimsClientBaseUrl`
  */

  useEffect(() => {
    console.log({pimsClientBaseUrl});
    const provetId = PIMS_DETAILS.provet.id;
    if (pimsTypeID.toString() === provetId && pimsPartnerID) {
      handleBlur()
    }
  }, [pimsClientBaseUrl, handleBlur, pimsPartnerID, pimsTypeID])

  const fetchClinicId = async () => {
    const kontakApiToken = getAccessToken();
    const response = await getClinicIDFromDigitail(kontakApiToken, accessToken);

    if (!response.isError && response.data) {
      const clinicId = response.data.clinicId;
      setPIMSOrgID(clinicId);
      setErrorPimsOrgId("");
    } else {
      setErrorPimsOrgId(response.message);
    }
  };

  const handleOauth2Blur = () => {
    if(accessToken && pimsTypeID.toString() === digitailId) {
      validateDuplicateCredentials();
    }

    if (!accessToken && pimsTypeID.toString() === digitailId) {
      setPIMSOrgID("");
      setErrorPimsOrgId("");
      return
    }

    if (!accessToken || pimsOrgID || pimsTypeID.toString() !== digitailId) {
      return
    }

    fetchClinicId();
  }
  

  // Block End  --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  return (
    <div className="section sec-move-left">
      <div className="section py-5 px-lg-2">
        <div className="section px-1">
          <div className="container-fluid z-bigger-10">
            <div className="row mt-3 mt-lg-4">
              <div className="col-auto align-self-center">
                <a
                  href="/settings/locations"
                  className="mb-0 font-weight-500 animsition-link link-back-img"
                >
                  <img
                    src={require("../img/left-arrow.svg")}
                    alt=""
                    className="mr-1"
                  />
                  back
                </a>
              </div>
              <div className="col-auto align-self-center">
                <h5 className="mb-0">Edit Locations</h5>
              </div>
            </div>
            <div className="row mt-2 pt-1">
              <div className="col-12">
                <div className="section background-white p-3 p-lg-4">
                  <div className="row">
                    <div className="col-md-3 col-xl-2 align-self-center pr-sm-I0">
                      <p className="mb-2 mb-md-0 font-weight-500">Location name</p>
                    </div>
                    <div className="col-md-5 col-xl-4 align-self-center">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-style border-0 small-40"
                          value={facilityName ?? ''}
                          onChange={(event) =>
                            setFacilityName(event.target.value)
                          }
                          onBlur={handleLocationNameBlur}
                          placeholder="Name your location..."
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    {isShepherdSelected && isLocationMatchError && (
                      <div className="col-md-4 col-xl-6 align-self-center">
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id="location-match-tooltip">
                              <ul className="mb-0">
                                {clinicsList.map((clinic, index) => (
                                  <li key={index}>{clinic.name}</li>
                                ))}
                              </ul>
                            </Tooltip>
                          }
                        >
                          <p className="mb-2 mb-md-0 text-xs text-warning cursor-pointer">
                            <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                            Error matching location to Shepherd Clinics. Hover for suggestions.
                          </p>
                        </OverlayTrigger>
                      </div>
                    )}
                  </div>
                  <TextInput label="Retriever ID" value={groupKey ?? ''} /> 
                  {isKontakAdmin() && (
                    <div className="row mt-3">
                      <div className="col-md-3 col-xl-2 align-self-center pr-sm-0">
                        <p className="mb-2 mb-md-0 font-weight-500">Telephony Type</p>
                      </div>
                      <div className="col-md-5 col-xl-4 align-self-center">
                        <div className="form-group">
                          <Select2
                            className="input-select-style no-search bigger-text"
                            data={[
                              { "id": 1, "text": 'PrimeVox' },
                              { "id": 2, "text": 'RingCentral' },
                              { "id": 3, "text": 'MS Teams' }
                            ]}
                            defaultValue={telephonyTypeID ?? ''}
                            options={{
                              placeholder: "Select a Telephony Type",
                              theme: "bootstrap4",
                              minimumResultsForSearch: -1,
                            }}
                            onChange={(event) =>
                              setTelephonyTypeID(event.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {isKontakAdmin() && <TextInput label="Container ID" value={containerID ?? ''} placeholder="Container ID..." autoComplete={false} onChange={(event) => setContainerID(event.target.value)} />}
                  {isKontakAdmin() && <TextInput label="Tenant ID" value={tenantID ?? ''} placeholder="Tenant ID..." autoComplete={false} onChange={(event) => setTenantID(event.target.value)} />}
                  <div className="row mt-3">
                    <div className="col-md-3 col-xl-2 align-self-center pr-sm-0">
                      <p className="mb-2 mb-md-0 font-weight-500">
                        PIMS Type
                      </p>
                    </div>
                    <div className="col-md-5 col-xl-4 align-self-center">
                      <div className="section z-bigger-20 height-40-style light-style">
                        {pimtypes && (
                          <Select2
                            className="input-select-style no-search bigger-text"
                            data={
                              pimtypes &&
                              pimtypes.sort(sortPimsTypeByLowercaseName).map((x) => ({ id: x.id, text: x.name }))
                            }
                            defaultValue={pimsTypeID ?? ''}
                            options={{
                              placeholder: "Select a PIMS Type",
                              theme: "bootstrap4",
                              minimumResultsForSearch: -1,
                            }}
                            onChange={handlePimsTypeChange}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  {shouldRenderPimsApiKey() && 
                    <TextInput 
                      label={createLabelByPimsTypeID(pimsTypeID.toString(), {
                            ezyvet: "Client Secret",
                            provet: "Client Secret",
                            default: "PIMS API Key",
                      })} 
                      value={pimsapiKey ?? ''} 
                      placeholder={createLabelByPimsTypeID(
                        pimsTypeID.toString(),
                        {
                          ezyvet: "Client Secret...",
                          provet: "Client Secret...",
                          default: "PIMS API Key...",
                        }
                      )}
                      autoComplete={false} 
                      onChange={(event) => setPIMSAPIKey(event.target.value)} 
                      onBlur={validateDuplicateCredentials}
                  />}
                  
                  {isKontakAdmin() && pimsTypeID === DIGITAIL_PIMS_TYPE_ID && <TextInput label="OAuth2 Token" value={accessToken ?? ''} placeholder="OAuth2 Token..." autoComplete={false} onChange={(event) => setAccessToken(event.target.value)} onBlur={handleOauth2Blur} errorMsg={errorOAuth2Token} />}
                  {isKontakAdmin() && 
                    <TextInput 
                      label={
                        createLabelByPimsTypeID(pimsTypeID.toString(), {
                          digitail: "Clinic ID",
                          ezyvet: "Client ID",
                          provet: "Client ID",
                          default: "PIMS Org ID",
                        })
                      } 
                      value={pimsOrgID ?? ''} 
                      placeholder={createLabelByPimsTypeID(
                        pimsTypeID.toString(),
                        {
                          digitail: "Clinic ID...",
                          ezyvet: "Client ID...",
                          provet: "Client ID...",
                          default: "PIMS Org ID...",
                        }
                      )} 
                      autoComplete={false} 
                      onChange={(event) => setPIMSOrgID(event.target.value)}
                      errorMsg={errorPimsOrgId}
                      onBlur={handlePimsOrgIDBlur}
                    />
                  }
                  
                  {isKontakAdmin() && CREDENTIAL_SUPPORTED_PIMS_IDS.includes(pimsTypeID.toString()) && (
                    <div className="row mt-3">
                      <div className="col-md-3 col-xl-2 align-self-center pr-sm-0">
                        {}
                      </div>
                      <div className="col-md-5 col-xl-4 align-self-center">
                        <div className="form-group d-flex align-items-center">
                          <button
                            onClick={validateCredentials}
                            className="btn btn-blue btn-width-130 btn-height-40 border-0 font-size-16"
                            disabled={shouldDisableButton()}
                          >
                            Validate Credentials
                          </button>
                    
                          {validationStatus === "valid" && (
                            <div className="text-success ml-3">
                              <span role="img" aria-label="check mark">✅</span> Valid
                            </div>
                          )}
                          {validationStatus === "invalid" && (
                            <div className="text-danger ml-3">
                              <span role="img" aria-label="cross mark">❌</span> Invalid
                            </div>
                          )}
                        </div>
                      </div>
                    </div>                                   
                  )}
                  
                  {shouldRenderPimsPartnerId() && 
                    <TextInput 
                      label={createLabelByPimsTypeID(pimsTypeID.toString(), {
                        provet: "Provet Cloud ID",
                        default: "PIMS Partner ID",
                      })}
                      value={pimsPartnerID ?? ''} 
                      placeholder={createLabelByPimsTypeID(
                        pimsTypeID.toString(),
                        {
                          provet: "Provet Cloud ID...",
                          default: "PIMS Partner ID...",
                        }
                      )}
                      autoComplete={false} 
                      onChange={(event) => setPIMSPartnerID(event.target.value)}
                    />
                  }

                  {isKontakAdmin() && (
                    <div className="row mt-3">
                      <div className="col-md-3 col-xl-2 align-self-center pr-sm-0">
                        <p className="mb-2 mb-md-0 font-weight-500">Features</p>
                      </div>
                      <div className="col-md-5 col-xl-4 align-self-center">
                        <div className="form-group">
                          <input
                            id="calendarFeature"
                            type="checkbox"
                            className="form-style border-0 small-40"
                            value={pimsPartnerID ?? false}
                            checked={!!features?.useCalendarFeature}
                            onChange={(event) =>
                              console.log(event.target.value) ||
                              setFeature({
                                useSmsFeature: !!features.useSmsFeature,
                                useCalendarFeature: !features.useCalendarFeature
                              })
                            }
                            placeholder="PIMS Partner ID..."
                            autoComplete="off"
                          />
                          <label htmlFor="calendarFeature" className="checkbox mb-0 d-inline-block">Calendar</label>
                        </div>
                        <div className="form-group">
                          <input
                            id="smsFeature"
                            type="checkbox"
                            value={!!features?.useSmsFeature ?? false}
                            checked={!!features?.useSmsFeature}
                            onChange={(event) =>
                              setFeature({
                                useCalendarFeature: !!features.useCalendarFeature,
                                useSmsFeature: !features.useSmsFeature
                              })
                            }
                            placeholder="PIMS Partner ID..."
                            autoComplete="off"
                          />
                          <label htmlFor="smsFeature" className="checkbox mb-0 d-inline-block">Sms</label>
                        </div>
                        <div className="form-group">
                          <input
                            id="callRecordingsFeature"
                            value={hideCallRecordings ?? false}
                            type="checkbox"
                            onChange={() => {
                              sethideCallRecordings(!hideCallRecordings);
                            }}
                            checked={hideCallRecordings ?? false}
                          />
                          <label htmlFor="callRecordingsFeature" className="checkbox mb-0 d-inline-block">Hide Call Recordings</label>
                        </div>
                      </div>
                    </div>
                  )}
                  {isKontakAdmin() && (
                    <TextInput label="Default Outbound Caller ID" value={outboundCallerID} onChange={(event) => setOutboundCallerID(event.target.value)} placeholder="Default Outbound Caller ID..." autoComplete={false} />
                  )}
                  {isKontakAdmin() && (
                    <>
                      <div className="row mt-3">
                        <div className="col-md-3 col-xl-2 align-self-center pr-sm-0">
                          <p className="mb-2 mb-md-0 font-weight-500">Default Outbound SMS number</p>
                        </div>
                        <div className="col-md-3 col-xl-2 align-self-center">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-style border-0 small-40"
                              value={sameAsOutboundCID ? outboundCallerID : outboundSmsNumber}
                              onChange={(event) =>
                                setOutboundSmsNumber(event.target.value)
                              }
                              placeholder="Default Outbound SMS number..."
                              autoComplete="off"
                              disabled={sameAsOutboundCID}
                            />
                          </div>
                        </div>
                        <div className="col-sm-3 col-md-2 align-self-center pr-sm-0">
                          <div className="form-group">
                            <input
                              id="sameAsOutboundCID"
                              type="checkbox"
                              onChange={() => {
                                setSameAsOutboundCID(!sameAsOutboundCID);
                                outboundCallerID === outboundSmsNumber && sameAsOutboundCID === false ? setOutboundSmsNumber('') : setOutboundSmsNumber(outboundSmsNumber);
                              }}
                              checked={sameAsOutboundCID ?? false}
                              disabled={outboundCallerID == null || outboundCallerID === ''}
                            />
                            <label htmlFor="sameAsOutboundCID" className="checkbox mb-0 d-inline-block">Same as Outbound CID</label>
                          </div>
                        </div>
                      </div>

                    </>
                  )}

                  {isKontakAdmin() &&
                    <TextInput
                      label="PIMS Client BaseURL"
                      value={pimsClientBaseUrl ?? ''}
                      placeholder={createPlaceholderToUrlBaseField(pimsTypeID.toString())}
                      autoComplete={false}
                      onChange={(event) => setPimsClientBaseUrl(event.target.value)}
                      onBlur={handleBlur}
                      ref={urlBaseInputRef}
                      errorMsg={errorURLBaseInput}
                    />
                  }
                  {isKontakAdmin() && <TextInput label="Voice System API Key" value={cloudRetrieverApiKey ?? ''} placeholder="Voice System API Key..." autoComplete={false} onChange={(event) => setCloudRetrieverApiKey(event.target.value)} />}
                  <div className="row mt-3">
                    <div className="col-md-3 col-xl-2 align-self-center pr-sm-0">
                      <p className="mb-2 mb-md-0 font-weight-500">
                        Part of a region
                      </p>
                    </div>
                    <div className="col-md-5 col-xl-4 align-self-center">
                      <div className="section z-bigger-20 height-40-style light-style">
                        {regions && (
                          <Select2
                            className="input-select-style no-search bigger-text"
                            data={
                              regions &&
                              regions.map((x) => ({ id: x.id, text: x.name }))
                            }
                            defaultValue={regionId ?? ''}
                            options={{
                              placeholder: "Select a region",
                              theme: "bootstrap4",
                              minimumResultsForSearch: -1,
                            }}
                            onChange={(event) =>
                              setRegionId(event.target.value)
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <TextInput label="Street Address" value={streetAddress ?? ''} placeholder="Street Address..." autoComplete={false} onChange={(event) => setStreetAddress(event.target.value)} />
                  <TextInput label="Address Line 2" value={addressLine ?? ''} placeholder="Address Line 2..." autoComplete={false} onChange={(event) => setAddressLine(event.target.value)} />
                  <TextInput label="City" value={city ?? ''} placeholder="City..." autoComplete={false} onChange={(event) => setCity(event.target.value)} />
                  <TextInput label="Zip code" value={zipCode ?? ''} placeholder="Zip code..." autoComplete={false} onChange={(event) => setZipCode(event.target.value)} />

                  <div className="row mt-3">
                    <div className="col-md-3 col-xl-2 align-self-center pr-sm-0">
                      <p className="mb-2 mb-md-0 font-weight-500">State</p>
                    </div>
                    <div className="col-md-5 col-xl-4 align-self-center">
                      <div className="section z-bigger-20 height-40-style light-style">
                        {states && (
                          <Select2
                            className="input-select-style no-search bigger-text"
                            data={
                              states &&
                              states.map((x) => ({ id: x.id, text: x.name }))
                            }
                            defaultValue={stateId ?? ''}
                            options={{
                              placeholder: "Select state",
                              theme: "bootstrap4",
                              minimumResultsForSearch: -1,
                            }}
                            onChange={(event) => setStateId(event.target.value)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-3 col-xl-2 align-self-center pr-sm-0">
                      <p className="mb-2 mb-md-0 font-weight-500">Timezone</p>
                    </div>
                    <div className="col-md-5 col-xl-4 align-self-center">
                      <div className="section z-bigger-20 height-40-style light-style">
                        {timeZones && (
                          <Select2
                            className="input-select-style no-search bigger-text"
                            data={
                              timeZones &&
                              timeZones.map((x) => ({
                                id: x.id,
                                text: x.timeZoneId,
                              }))
                            }
                            defaultValue={timeZoneId ?? ''}
                            options={{
                              placeholder: "Select state",
                              theme: "bootstrap4",
                              minimumResultsForSearch: -1,
                            }}
                            onChange={(event) =>
                              setTimeZoneId(event.target.value)
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  {businessHours &&
                    PrivilegeChecker.hasPrivilege(
                      Privileges.ViewEditWorkHours
                    ) && (
                      <div className="row mt-3">
                        <div className="col-md-3 col-xl-2 pr-sm-0">
                          <p className="mb-2 mb-md-0 font-weight-500">
                            Working hours
                          </p>
                        </div>
                        <div className="col-md-7 col-xl-5">
                          <div className="section">
                            <div className="form-group">
                              <p className="mb-0 font-size-14 d-inline-block workh-p-width">
                                Monday
                              </p>
                              <input
                                type="checkbox"
                                className="working-time-close"
                                onChange={() => {
                                  setMondayIsClosed(!mondayIsClosed);
                                }}
                                checked={mondayIsClosed}
                                id="closed-1"
                              />
                              <label
                                className="checkbox mb-0 d-inline-block"
                                htmlFor="closed-1"
                              >
                                Closed
                              </label>
                              <CustomSlider
                                from={toTime(businessHours.mondayOpen)}
                                to={toTime(businessHours.mondayClose)}
                                onChange={(slider) => {
                                  businessHours.mondayOpen = moment(
                                    slider.from
                                  ).format("HH:mm:ss");
                                  businessHours.mondayClose = moment(
                                    slider.to
                                  ).format("HH:mm:ss");
                                }}
                                className={"range-slider slider-1"}
                                hide_min_max={true}
                                type={"double"}
                                skin={"flat"}
                                prettify={extractTime}
                                step={1800000}
                                min={toTime("00:00:00")}
                                max={toTime("23:59:59")}
                              />
                            </div>
                            <div className="form-group mt-3">
                              <p className="mb-0 font-size-14 d-inline-block workh-p-width">
                                Tuesday
                              </p>
                              <input
                                type="checkbox"
                                className="working-time-close"
                                onChange={() => {
                                  setTuesdayIsClosed(!tuesdayIsClosed);
                                }}
                                checked={tuesdayIsClosed}
                                id="closed-2"
                              />
                              <label
                                className="checkbox mb-0 d-inline-block"
                                htmlFor="closed-2"
                              >
                                Closed
                              </label>
                              <CustomSlider
                                onChange={(slider) => {
                                  businessHours.tuesdayOpen = moment(
                                    slider.from
                                  ).format("HH:mm:ss");
                                  businessHours.tuesdayClose = moment(
                                    slider.to
                                  ).format("HH:mm:ss");
                                }}
                                from={toTime(businessHours.tuesdayOpen)}
                                to={toTime(businessHours.tuesdayClose)}
                                className={"range-slider slider-1"}
                                hide_min_max={true}
                                type={"double"}
                                skin={"flat"}
                                prettify={extractTime}
                                step={1800000}
                                min={toTime("00:00:00")}
                                max={toTime("23:59:59")}

                              />
                            </div>
                            <div className="form-group mt-3">
                              <p className="mb-0 font-size-14 d-inline-block workh-p-width">
                                Wednesday
                              </p>
                              <input
                                type="checkbox"
                                className="working-time-close"
                                onChange={() => {
                                  setWednesdayIsClosed(!wednesdayIsClosed);
                                }}
                                checked={wednesdayIsClosed}
                                id="closed-3"
                              />
                              <label
                                className="checkbox mb-0 d-inline-block"
                                htmlFor="closed-3"
                              >
                                Closed
                              </label>
                              <CustomSlider
                                onChange={(slider) => {
                                  businessHours.wednesdayOpen = moment(
                                    slider.from
                                  ).format("HH:mm:ss");
                                  businessHours.wednesdayClose = moment(
                                    slider.to
                                  ).format("HH:mm:ss");
                                }}
                                from={toTime(businessHours.wednesdayOpen)}
                                to={toTime(businessHours.wednesdayClose)}
                                className={"range-slider slider-1"}
                                hide_min_max={true}
                                type={"double"}
                                skin={"flat"}
                                prettify={extractTime}
                                step={1800000}
                                min={toTime("00:00:00")}
                                max={toTime("23:59:59")}

                              />
                            </div>
                            <div className="form-group mt-3">
                              <p className="mb-0 font-size-14 d-inline-block workh-p-width">
                                Thursday
                              </p>
                              <input
                                type="checkbox"
                                className="working-time-close"
                                onChange={() => {
                                  setThursdayIsClosed(!thursdayIsClosed);
                                }}
                                checked={thursdayIsClosed}
                                id="closed-4"
                              />
                              <label
                                className="checkbox mb-0 d-inline-block"
                                htmlFor="closed-4"
                              >
                                Closed
                              </label>
                              <CustomSlider
                                onChange={(slider) => {
                                  businessHours.thursdayOpen = moment(
                                    slider.from
                                  ).format("HH:mm:ss");
                                  businessHours.thursdayClose = moment(
                                    slider.to
                                  ).format("HH:mm:ss");
                                }}
                                from={toTime(businessHours.thursdayOpen)}
                                to={toTime(businessHours.thursdayClose)}
                                className={"range-slider slider-1"}
                                hide_min_max={true}
                                type={"double"}
                                skin={"flat"}
                                prettify={extractTime}
                                step={1800000}
                                min={toTime("00:00:00")}
                                max={toTime("23:59:59")}

                              />
                            </div>
                            <div className="form-group mt-3">
                              <p className="mb-0 font-size-14 d-inline-block workh-p-width">
                                Friday
                              </p>
                              <input
                                type="checkbox"
                                className="working-time-close"
                                onChange={() => {
                                  setFridayIsClosed(!fridayIsClosed);
                                }}
                                checked={fridayIsClosed}
                                id="closed-5"
                              />
                              <label
                                className="checkbox mb-0 d-inline-block"
                                htmlFor="closed-5"
                              >
                                Closed
                              </label>
                              <CustomSlider
                                onFinish={function (slider) {
                                  console.log(this.scope)
                                  let copy = { ...businessHours };
                                  copy.fridayOpen = moment(
                                    slider.from
                                  ).format("HH:mm:ss");
                                  copy.fridayClose = moment(
                                    slider.to
                                  ).format("HH:mm:ss");
                                  setBusinessHours(copy);
                                }}
                                from={toTime(businessHours.fridayOpen)}
                                to={toTime(businessHours.fridayClose)}
                                className={"range-slider slider-1"}
                                hide_min_max={true}
                                type={"double"}
                                skin={"flat"}
                                prettify={extractTime}
                                step={1800000}
                                min={toTime("00:00:00")}
                                max={toTime("23:59:59")}

                              />
                            </div>
                            <div className="form-group mt-3">
                              <p className="mb-0 font-size-14 d-inline-block workh-p-width">
                                Saturday
                              </p>
                              <input
                                type="checkbox"
                                className="working-time-close"
                                onChange={() => {
                                  setSaturdayIsClosed(!saturdayIsClosed);
                                }}
                                checked={saturdayIsClosed}
                                id="closed-6"
                              />
                              <label
                                className="checkbox mb-0 d-inline-block"
                                htmlFor="closed-6"
                              >
                                Closed
                              </label>
                              <CustomSlider
                                onChange={(slider) => {
                                  businessHours.saturdayOpen = moment(
                                    slider.from
                                  ).format("HH:mm:ss");
                                  businessHours.saturdayClose = moment(
                                    slider.to
                                  ).format("HH:mm:ss");
                                }}
                                from={toTime(businessHours.saturdayOpen)}
                                to={toTime(businessHours.saturdayClose)}
                                className={"range-slider slider-1"}
                                hide_min_max={true}
                                type={"double"}
                                skin={"flat"}
                                prettify={extractTime}
                                step={1800000}
                                min={toTime("00:00:00")}
                                max={toTime("23:59:59")}

                              />
                            </div>
                            <div className="form-group mt-3">
                              <p className="mb-0 font-size-14 d-inline-block workh-p-width">
                                Sunday
                              </p>
                              <input
                                type="checkbox"
                                className="working-time-close"
                                onChange={() => {
                                  setSundayIsClosed(!sundayIsClosed);
                                }}
                                checked={sundayIsClosed}
                                id="closed-7"
                              />
                              <label
                                className="checkbox mb-0 d-inline-block"
                                htmlFor="closed-7"
                              >
                                Closed
                              </label>
                              <CustomSlider
                                onChange={(slider) => {
                                  businessHours.sundayOpen = moment(
                                    slider.from
                                  ).format("HH:mm:ss");
                                  businessHours.sundayClose = moment(
                                    slider.to
                                  ).format("HH:mm:ss");
                                }}
                                from={toTime(businessHours.sundayOpen)}
                                to={toTime(businessHours.sundayClose)}
                                className={"range-slider slider-1"}
                                hide_min_max={true}
                                type={"double"}
                                skin={"flat"}
                                prettify={extractTime}
                                step={1800000}
                                min={toTime("00:00:00")}
                                max={toTime("23:59:59")}

                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  {(isKontakAdmin() || PrivilegeChecker.hasPrivilege(Privileges.ViewEditClientValue)) && (<>
                    <TextInput label="New appt. requests converted to appt." value={appointmentsPercentage ?? ''} placeholder="" autoComplete={false} onChange={(event) => setAppointmentsPercentage(event.target.value)} />
                    <TextInput label="Average CLV" value={averageClientValue ?? ''} placeholder="" autoComplete={false} onChange={(event) => setAverageClientValue(event.target.value)} />
                  </>)}
                  <div className="row mt-4">
                    <div className="col-12">
                      <button
                        // onClick={() => updateGroup()}
                        onClick={clickHandler}
                        className="btn btn-blue btn-width-130 btn-height-40 border-0 font-size-16"
                        disabled={shouldDisableButton()}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditLocation;
