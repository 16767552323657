import Rox from 'rox-browser';

export const flags = {
  preventSharedAuthCredentials: new Rox.Flag()
};

export async function initializeRollout() {
  const sdkKey = process.env.REACT_APP_ROLLOUT_SDK_KEY;
  if (!sdkKey) {
    console.warn('Rollout SDK key is missing.');
    return;
  }

  Rox.register('Release.KontakUI', {
    PreventSharedAuthCredentials: flags.preventSharedAuthCredentials,
  });

  try {
    const fetchIntervalSeconds = process.env.REACT_APP_ROLLOUT_FETCH_INTERVAL_SECONDS;
    if (!fetchIntervalSeconds) {
      console.warn('Fetch interval seconds is missing.');
      return;
    }

    await Rox.setup(sdkKey, {
      fetchIntervalInSeconds: fetchIntervalSeconds
    });
    console.log('Rollout initialized');
  } catch (err) {
    console.error('Rollout failed to initialize:', err);
  }
}
